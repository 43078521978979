import { Box, Image } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useRef, useState } from 'react'
import Header from '../header'
import SelectWalletModal from '../Modal/SelectWalletModal'
import PageContent from '../page-content'
import TermsAndConditions from '../terms-and-conditions'
import style from './index.module.scss'
const Wrapper = {
  background: 'url(./img/home/bg.png) center/cover no-repeat',
  height: '100vh',
  position: 'relative'
}

export default function Home() {
  const router = useRouter()
  const account = localStorage.getItem('WALLET_ADDRESS')
  const [next, setNext] = useState(false)
  const [showWallet, setShowWallet] = useState(false)
  const [step, setStep] = useState(0)
  const [readyToDoor, setReadyToDoor] = useState(false)
  const buttonConfig = [
    {},
    {
      tip: 'Santa’s Workshop is a vast and intricate place full of amazing things. I mean, take the Transmogrifier Room for instance. Run by the Elves…',
      tipStyle: {
        width: '35%',
        height: '28%',
        padding: '4%'
      },
      buttons: [
        {
          text: 'What’s the Transmogrifier Room?',
          click: () => {
            setStep(2)
          }
        },
        {
          text: 'I know all about it!',
          click: () => {
            if (account) {
              router.push({
                pathname: '/open-door-pre'
              })
            } else {
              setShowWallet(true)
            }
          }
        }
      ]
    },
    {
      tip: 'First off, The North Pole is a mysterious place. Once there was a closet that appeared off the elves workshop that had nothing but a platform with a ham and cheese sandwich on it. No one ate it. Couple days later the closet was gone.',
      tipStyle: {
        width: '38%',
        height: '31%',
        padding: '2%'
      },
      buttons: [
        {
          text: 'But what about the Transmogrifier??',
          click: () => {
            setStep(3)
          }
        },
        {
          text: 'I don’t care…',
          click: () => {
            if (account) {
              router.push({
                pathname: '/open-door-pre'
              })
            } else {
              setShowWallet(true)
            }
          }
        }
      ]
    },
    {
      tip: 'Well, the Transmogrifier - that’s all part of Santa’s deal. That guy is a tinkerer, you know? He’s the intersection of magic and science (I mean you gotta do something with the other 364 days of the year, right?). So he’s always working on something and it’s usually a gadget and it’s usually in service of understanding the mysteries of Christmas Magic.',
      tipStyle: {
        width: '45%',
        height: '35%',
        padding: '2%'
      },
      buttons: [
        {
          text: 'Why’s Christmas Magic so important?',
          click: () => {
            setStep(4)
          }
        }
      ]
    },
    {
      tip: 'The whole thing falls apart without Christmas Magic!',
      tipStyle: {
        width: '24%',
        height: '22%',
        padding: '2%'
      },
      buttons: [
        {
          text: 'Right… So about the Transmogrifier.',
          click: () => {
            setStep(5)
          }
        },
        {
          text: 'Just go to the Transmogrifier…',
          click: () => {
            if (account) {
              router.push({
                pathname: '/open-door-pre'
              })
            } else {
              setShowWallet(true)
            }
          }
        }
      ]
    },
    {
      tip: 'Nobody knows exactly what it is or where it comes from. Santa is the foremost expert on the stuff. It’s why he built the Transmogrifier Room. This device helps remove and/or distill Christmas Magic. This is the stuff Santa uses to power his sleigh, navigate tricky chimneys, and occasionally bring inanimate objects to life.',
      tipStyle: {
        width: '45%',
        height: '40%',
        padding: '2%'
      },
      buttons: [
        {
          text: 'What happened?',
          click: () => {
            setStep(6)
          }
        }
      ]
    },
    {
      tip: 'Oh yeah. Krampus’ minions have been creeping all around the North Pole. They targeted and broke into the Transmogrification Room last week! Did some weird stuff to it. You do NOT want to go in there until we can get it fixed and cleaned.',
      tipStyle: {
        width: '36%',
        height: '34%',
        padding: '2%'
      },
      buttons: [
        {
          text: 'I’ll take my chances…',
          click: () => {
            if (account) {
              router.push({
                pathname: '/open-door-pre'
              })
            } else {
              setShowWallet(true)
            }
          }
        },
        {
          text: 'Yeah, never mind. I’ll pass',
          click: () => {
            // setReadyToDoor(true)
            setShowWallet(false)
            setTimeout(() => {
              location.reload()
            }, 1000)
          }
        }
      ]
    }
  ]

  const connectedWallet = () => {
    setReadyToDoor(true)
    setTimeout(() => {
      router.push({
        pathname: '/open-door-pre'
      })
    }, 900)
  }

  const roomRef = useRef<HTMLDivElement>()

  return (
    <PageContent
      roomRef={roomRef}
      wrapperClassName={style.wrapper}
      fillClassName={`${style.fill} ${next && style.fill_door}`}
      contentClassName={style.content}
    >
      <div className={`${style.bg1} ${next ? style.bg1_to_top : ''}`}>
        <Header connectedWallet={connectedWallet} />
        <Box
          sx={{
            background: 'url(./img/home/bg.png) center/cover no-repeat',
            height: '100%',
            position: 'relative'
          }}
        >
          <div className={style.christmas_header2}></div>
          {/* <Image src="./img/home/christmas-header.png" alt="" width="65%"></Image> */}
          {/* <Image
          src="./img/home/Krampus.png"
          alt=""
          sx={{
            width: '30.26%',
            position: 'absolute',
            top: '12.396%',
            left: '33.02%'
          }}
        ></Image> */}
          <Image
            src="./img/home/He-Sees-You.svg"
            alt=""
            sx={{
              width: '55.1%',
              position: 'absolute',
              top: '14.114%',
              left: '22.448%'
            }}
          />
          <Image
            src="./img/home/snow-header_background_hole.png"
            alt=""
            sx={{
              width: '100%',
              position: 'absolute',
              bottom: '0',
              left: '0'
            }}
          />
          <Image
            src="./img/home/snow-header_background.png"
            alt=""
            sx={{
              width: next ?'0': '100%',
              position: 'absolute',
              bottom: '0',
              left: '0'
            }}
          />
          {/* <Image src="./img/home/snow_hole.png" className={`${style.snow_hole} ${next && style.snow_hole_show}`} alt=""></Image> */}
        </Box>
      </div>
      <Box
        className={`${style.bot_button} ${next ? style.bot_button_to_bottom : ''}`}
        onClick={() => {
          setNext(true)
        }}
      ></Box>
      <Image src="./img/home/snow-header_background_1.png" className={`${style.snow_hole} ${next && style.snow_hole_show}`} alt=""></Image>
      <Image src="./img/home/snow-header_background_2.png" className={`${style.snow_hole_2} ${next && style.snow_hole_2_show}`} alt=""></Image>
      <div className={`${style.bg2_door} ${next ? style.bg2_door_to_top : ''}`}></div>
      <div className={`${style.bg2} ${next ? style.bg2_to_top : ''}`}>
        <Header logo={<Image className={`${readyToDoor ? style.logo_to_top : style.logo}`} src="./img/home/MerryModz-Logo.png" alt="" />} />
        {step && !readyToDoor ? (
          <>
            <div className={style.bg2_filter}></div>
          </>
        ) : (
          <></>
        )}
        <div
          className={`${style.boot_tip_bot} ${next ? style.boot_tip_bot_to_right : ''} ${step ? style.boot_tip_bot_action : ''} ${
            next && readyToDoor ? style.boot_tip_bot_to_left : '---'
          } `}
          onClick={() => setStep(1)}
        ></div>

        {step && !readyToDoor ? (
          <>
            <div className={style.speech_bubble} style={buttonConfig[step]?.tipStyle}>
              {buttonConfig[step]?.tip}
            </div>
          </>
        ) : (
          <></>
        )}
        <div className={`${style.button_wrapper} ${step && !readyToDoor ? style.button_wrapper_to_top : ''}`}>
          <div className={style.button_content}>
            {buttonConfig[step]?.buttons?.map((buttonItem) => (
              <div key={buttonItem.text} className={style.button_item} onClick={() => buttonItem.click()}>
                {buttonItem.text}
              </div>
            ))}
          </div>
        </div>
      </div>
      <SelectWalletModal
        isOpen={showWallet}
        closeModal={() => {
          !readyToDoor && setShowWallet(false)
        }}
        connectedWallet={connectedWallet}
      />
      <TermsAndConditions></TermsAndConditions>
      <iframe width={0} height={0} src="/open-door-pre" />
    </PageContent>
  )
}
